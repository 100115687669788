@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    * {
        @apply font-din
    }

    html, body {
        @apply bg-gray-900 text-gray-400 m-0 p-0 overflow-x-hidden

    }

    body {
        @apply max-w-full
    }
    
    a {
        @apply cursor-pointer;
    }

    a:hover {
        @apply text-purple-800
    }

    p {
        @apply text-sm md:text-lg
    }
}

@layer components {
    .contact-input {
        @apply h-[50px] bg-gray-600 text-base border border-purple-800 px-5 w-full m-1 ml-0 overflow-hidden placeholder-opacity-0
    }

    ::placeholder {
        @apply placeholder-opacity-50
    }

    .contact-li {
        @apply overflow-hidden w-full
    }

    .contact-li-half {
        @apply w-[49%]
    }

    .skewed-border-right {
        -webkit-transform: skewX(-10deg);
        -moz-transform: skewX(-10deg);
        -ms-transform: skewX(-10deg);
        transform: skewX(-10deg);
        @apply border-purple-800 border
    }

    .skewed-border-left {
        -webkit-transform: skewX(10deg);
        -moz-transform: skewX(10deg);
        -ms-transform: skewX(10deg);
        transform: skewX(10deg);
        @apply border-purple-800 border
    }

    .navActive {
        @apply border-purple-800
    }

    .mobile-nav-btn {
        @apply text-4xl uppercase px-2 pb-4 font-bold 
    }

    
}


